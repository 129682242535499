// extracted by mini-css-extract-plugin
export var info = "info-module--info--2oBqQ";
export var fadeIn = "info-module--fade-in--8iJ9s";
export var description = "info-module--description--3KNYY";
export var headingBox = "info-module--headingBox--1Q961";
export var tech = "info-module--tech--EIqZO";
export var listBox = "info-module--listBox--JJ488";
export var infoHeading = "info-module--infoHeading--3nrXk";
export var contact = "info-module--contact--Ta5xW";
export var paraLink = "info-module--paraLink--7ong7";
export var home = "info-module--home--3HKhi";